/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-28 17:49:24
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-17 11:00:02
 * @FilePath: \app_wb\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%
 */
import axios from "axios";

// 2.创建一个实例

axios.defaults.withCredentials = true; //跨越

//axios.defaults.headers.common['Cookie'] = "Admin-Token=eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImU5ZmE3ZWNjLWM3NWYtNDBhZS05YzYyLTA4MTUzYWY5MTJkYiJ9.K5vh8OIoIxjVWFGIY4vq-UHTr2VfM88f3v9esQ_hNC0eK14K81X00J5oB7Bd5_1GrucgzccCmlQFXQ2_0CcxzQ; sidebarStatus=0";
const api = axios.create({
  baseURL: "https://in.xtbg.ac.cn:8442/prod-api", // 请求的公共地址部分http://in.xtbg.ac.cn:8181
  timeout: 6000, // 请求超时时间 当请求时间超过5秒还未取得结果时 提示用户请求超时
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImFlZDlmOGU4LWI5MWUtNGJkMS05M2RkLWVlOGVlNWUxN2JhNiJ9.k9aX-HfpR2kkeMA55-VmpBMUd9kDObUfnUlUsXY6hFKEooOMIOZxbHzmkXa3o9ZyKJNJL6nuyrJdPDg98dbLSA",
  },
  withCredentials: true,
});

// 3axios的拦截器
// 请求相关处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
api.interceptors.request.use(
  (config) => {
    // config 请求的信息
    return config; // 将配置完成的config对象返回出去 如果不返回 请求则不会进行
  },
  (err) => {
    // 请求发生错误时的处理 抛出错误
    Promise.reject(err);
  }
);

// 4.response
api.interceptors.response.use(
  (res) => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
    // res 是所有相应的信息

    return Promise.resolve(res);
  },
  (err) => {
    // 服务器响应发生错误时的处理
    Promise.reject(err);
  }
);

// 5.暴露api
export default api;
